import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfo';
import { User } from '../../../contexts/user';
import { ModuloTitle } from '../../../components/moduloTitle';
import MAPI from '../../../api/mainApi';
import { ListContatos } from './components/listContatos';
import { EditContato } from './components/editContato';
import { ContTags } from './components/contatoTags';
import { validaEvento } from '../../../Mainfunctions';

export function MeusContatos({ modulo }) {
    document.title = `Meus Contatos - It's Chat`
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [contatos, setContatos] = useState([])
    const [contatoCampos, setContatoCampos] = useState(null)
    const [editContato, setEditContato] = useState({
        visible: false,
        contato: null
    })
    const [contatoTags, setContatoTags] = useState({
        visible: false,
        contato: null
    })

    async function getCamposContato() {
        updateModalInfo(true, false, 'Carregando', 'Buscando campos personalizados', 'loading')
        const get = await MAPI('camposContato/getCampos', 'GET', null, user?.data?.token)
        updateModalInfo(false)
        const apiData = get?.apiReturn?.apiReturn
        if (get?.status === 200 && apiData.length > 0) {
            setContatoCampos(apiData)
        }
        else {
            setContatoCampos(false)
        }
    }

    function pushContato(contato) {
        const newCont = [...contatos]
        newCont.unshift(contato)
        setContatos(newCont)
        updateModalInfo(true, true, 'Atenção', 'Contato salvo com sucesso.', 'sucess')
    }
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function updateContato(contato) {
        const newContatos = [...contatos]
        const getIndex = newContatos.findIndex((e) => e?.id === contato?.id)
        newContatos[getIndex] = contato
        setContatos(newContatos)
        updateModalInfo(true, true, 'Atenção', 'Contato editado com sucesso.', 'sucess')
        setEditContato({
            visible: false,
            contato: null
        })
    }
    function delContato(contato) {
        const filterContatos = contatos.filter((e) => e.id !== contato.id)
        setContatos(filterContatos)
    }
    async function getData() {
        updateModalInfo(true, false, 'Atenção', 'Buscando contatos', 'loading')
        const get = await MAPI('meusContatos/list', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Não foi possível buscar os contatos.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        setContatos(get?.apiReturn?.apiReturn)
    }
    function updateContTags(visible, contato) {
        const newEdit = { ...contatoTags }
        newEdit.visible = visible ? visible : false
        newEdit.contato = contato ? contato : null
        setContatoTags(newEdit)
    }
    function openEdit(visible, contato) {
        if (!validaEvento(modulo?.permissoes, 'criar') && !contato && visible) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissões para criar um novo contato.', 'exclamation')
            return
        }
        const newEdit = { ...editContato }
        newEdit.visible = visible ? visible : false
        newEdit.contato = contato ? contato : null
        setEditContato(newEdit)
    }
    useEffect(() => {
        getData()
    }, [])

    return (
        <Box sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            paddingBottom: 5
        }}>
            <ModuloTitle icon={modulo.moduloIcon} title={modulo.moduloNome} desc={modulo.moduloDesc} buttons={[{ text: 'Adicionar', func: () => openEdit(true) }]} />
            <Box sx={{ height: '100%' }}>
                <ListContatos canExclude={validaEvento(modulo?.permissoes, 'excluir')} contatos={contatos} openEdit={openEdit} updateContato={updateContato} delContato={delContato} updateContTags={updateContTags} />
            </Box>
            <EditContato getCamposContato={getCamposContato} contatoCampos={contatoCampos} canEdit={validaEvento(modulo?.permissoes, 'editar')} visible={editContato.visible} prevContato={editContato.contato} dismiss={openEdit} updateContato={updateContato} pushContato={pushContato} />
            <ContTags canEdit={validaEvento(modulo?.permissoes, 'editar')} visible={contatoTags.visible} prevContato={contatoTags.contato} updateContato={updateContato} updateContTags={updateContTags} />
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}