import { forwardRef, useMemo, useState, useRef, useContext, useEffect } from "react";
import { useTheme, TextField, Box, IconButton, Menu, MenuItem, Autocomplete, ListItemButton, Typography, Collapse, styled } from '@mui/material'
import EmojiPicker from 'emoji-picker-react';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'
import MAPI from "../api/mainApi";
import { User } from "../contexts/user";
import { ModalInfo } from "./modalInfo";
import { Variaveis } from "../contexts/variaveis";
import { Link } from "react-router-dom";
import { getIcon } from "./icons";
import SearchIcon from '@mui/icons-material/Search';


export const CTextField = forwardRef(({
    isVariable, value, type, onChangeText, label, security, icon, iconPosition,
    autoFocus, onKeyPress, prevData, width, onBlur, disabled, disableAutoComplete, referencias, newTheme
}, ref) => {
    const baseTheme = useTheme()
    const theme = newTheme ? newTheme : baseTheme
    const user = useContext(User);
    const [anchorEl, setAnchorEl] = useState(null);
    const [cursorPosition, setCursorPosition] = useState(0);
    const textFieldRef = useRef(null);
    const variaveis = useContext(Variaveis)?.data
    const [isReferencia, setIsReferencia] = useState(false)
    const handleVariableClick = (variable) => {
        const newValue = [value.slice(0, cursorPosition), variable, value.slice(cursorPosition)].join('');
        if (onChangeText) {
            onChangeText(newValue, type);
        }
        setAnchorEl(null);
    };

    const handleKeyDown = async (event) => {
        if (onKeyPress) {
            onKeyPress(event, type);
        }

        if (event.key === '{' && event.target.value[event.target.selectionStart - 1] === '{' && isVariable && variaveis.length > 0) {
            setCursorPosition(event.target.selectionStart);
            setAnchorEl(event.currentTarget);
            setIsReferencia(false)
        }
        if (event.key === '[' && event.target.value[event.target.selectionStart - 1] === '[' && isVariable && referencias?.length > 0) {
            setCursorPosition(event.target.selectionStart);
            setAnchorEl(event.currentTarget);
            setIsReferencia(true)
        }
    };

    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    const whatVariavel = isReferencia && Array.isArray(referencias) && referencias?.length > 0 ? referencias : variaveis

    return useMemo(() => (
        <Box sx={{ position: 'relative', width: width || '100%' }}>
            <TextField
                disabled={disabled || false}
                inputRef={ref || textFieldRef}
                autoFocus={autoFocus || false}
                variant='standard'
                onKeyDown={handleKeyDown}
                onBlur={() => {
                    if (onBlur) {
                        onBlur(type);
                    }
                }}
                inputProps={{
                    style: { color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.text.primary },
                    autoComplete: 'off'
                }}
                type={security ? 'password' : 'text'}
                InputProps={icon ? {
                    startAdornment: iconPosition === 'start' ? icon : null,
                    endAdornment: iconPosition === 'end' ? icon : null
                } : null}
                label={label || ' '}
                InputLabelProps={{
                    style: {
                        color:  theme.palette.mode === 'dark' ? '#fff' : theme.palette.text.primary 
                    }
                }}
                value={value}
                onChange={(event) => {
                    if (onChangeText) {
                        onChangeText(event.target.value, type);
                    }
                }}
                sx={{
                    width: width || '100%'
                }}
            />
            {whatVariavel ?
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    {whatVariavel.map((variavel, index) => (
                        <MenuItem key={index} onClick={() => handleVariableClick(variavel.nome.replace('{{', '{').replace('[[', '['))}>
                            {variavel.nome}
                        </MenuItem>
                    ))}
                </Menu>
                : null}
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    ), [value, type, prevData, anchorEl, MInfo]);
});
export const BTextField = forwardRef(({
    isVariable, multiline, maxRows, minRows, isVariaveis, value, type, onChangeText, label, security, icon, iconPosition,
    autoFocus, onKeyPress, prevData, width, onBlur, disabled
}, ref) => {
    const theme = useTheme();
    const user = useContext(User);
    const [anchorEl, setAnchorEl] = useState(null);
    const [cursorPosition, setCursorPosition] = useState(0);
    const textFieldRef = useRef(null);
    const variaveis = useContext(Variaveis)?.data

    const handleVariableClick = (variable) => {
        const newValue = [value.slice(0, cursorPosition), variable, value.slice(cursorPosition)].join('');
        if (onChangeText) {
            onChangeText(newValue, type);
        }
        setAnchorEl(null);
    };

    const handleKeyDown = async (event) => {
        if (!isVariaveis) return;
        if (onKeyPress) {
            onKeyPress(event, type);
        }
        if (event.key === '{' && event.target.value[event.target.selectionStart - 1] === '{' && isVariable && variaveis.length > 0) {
            setCursorPosition(event.target.selectionStart);
            setAnchorEl(event.currentTarget);
        }
    };

    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    return useMemo(() => (
        <Box sx={{ position: 'relative', width: width || '100%' }}>
            <TextField
                disabled={disabled || false}
                inputRef={ref || textFieldRef}
                autoFocus={autoFocus || false}
                variant='outlined'
                onKeyDown={handleKeyDown}
                onBlur={() => {
                    if (onBlur) {
                        onBlur(type);
                    }
                }}
                multiline={multiline || false}
                minRows={minRows || 10}
                maxRows={maxRows || 10}
                type={security ? 'password' : 'text'}
                InputProps={{
                    startAdornment: (icon && iconPosition === 'start') ? icon : null,
                    endAdornment: (icon && iconPosition === 'end') ? icon : null
                }}
                label={label || ' '}
                value={value}
                onChange={(event) => {
                    if (onChangeText) {
                        onChangeText(event.target.value, type);
                    }
                }}
                sx={{
                    width: width || '100%'
                }}
            />
            {variaveis ?
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    {variaveis.map((variavel, index) => (
                        <MenuItem key={index} onClick={() => handleVariableClick(variavel.nome.replace('{{', '{'))}>
                            {variavel.nome}
                        </MenuItem>
                    ))}
                </Menu>
                : null}
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    ), [value, type, prevData, anchorEl, MInfo]);
});
export const CMultiTextField = forwardRef(({
    minRows, maxRows, isVariable, value, type, onChangeText, label, security, icon, iconPosition,
    autoFocus, onKeyPress, prevData, width, onBlur, disabled, referencias, newTheme
}, ref) => {
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [isReferencia, setIsReferencia] = useState(false)
    const textFieldRef = useRef(null);
    const variaveis = useContext(Variaveis)?.data
    const baseTheme = useTheme()
    const theme = newTheme ? newTheme : baseTheme

    const handleEmojiClick = (emojiObject) => {
        setShowEmojiPicker(false);
        if (onChangeText) {
            onChangeText(value + emojiObject.emoji, type);
        }
    };

    const handleVariableClick = (variable) => {
        const newValue = [value.slice(0, cursorPosition), variable, value.slice(cursorPosition)].join('');
        if (onChangeText) {
            onChangeText(newValue, type);
        }
        setAnchorEl(null);
    };

    const handleKeyDown = async (event) => {
        if (onKeyPress) {
            onKeyPress(event, type);
        }
        if (event.key === '{' && event.target.value[event.target.selectionStart - 1] === '{' && isVariable && variaveis?.length > 0) {
            setCursorPosition(event.target.selectionStart);
            setAnchorEl(event.currentTarget);
            setIsReferencia(false)
        }
        if (event.key === '[' && event.target.value[event.target.selectionStart - 1] === '[' && isVariable && referencias?.length > 0) {
            setCursorPosition(event.target.selectionStart);
            setAnchorEl(event.currentTarget);
            setIsReferencia(true)
        }
    };

    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    useEffect(() => {
        console.log(theme.palette.mode)
    }, [theme.palette.mode])
    const whatVariavel = isReferencia && Array.isArray(referencias) && referencias?.length > 0 ? referencias : variaveis
    return useMemo(() => (
        <Box sx={{ position: 'relative', width: width || '100%' }}>
            <TextField
                disabled={disabled || false}
                inputRef={ref || textFieldRef}
                autoFocus={autoFocus || false}
                variant='standard'
                onKeyDown={handleKeyDown}
                onBlur={() => {
                    if (onBlur) {
                        onBlur(type);
                    }
                }}
                type={security ? 'password' : 'text'}
                inputProps={{ style: { color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.text.primary } }}
                InputProps={{
                    startAdornment: (icon && iconPosition === 'start') ? icon : null,
                    endAdornment: (
                        <>
                            {(icon && iconPosition === 'end') ? icon : null}
                            <IconButton onClick={() => setShowEmojiPicker(prev => !prev)}>
                                <EmojiEmotionsIcon />
                            </IconButton>
                        </>
                    )
                }}
                multiline
                minRows={minRows ? minRows : 10}
                maxRows={maxRows ? maxRows : 10}
                label={label || ' '}
                InputLabelProps={{
                    style: {
                        color: theme.palette.text.primary
                    }
                }}
                value={value}
                onChange={(event) => {
                    if (onChangeText) {
                        onChangeText(event.target.value, type);
                    }
                }}
                sx={{ width: '100%', color: 'red' }}
            />
            {showEmojiPicker && (
                <Box sx={{ position: 'absolute', top: 0, zIndex: 1 }}>
                    <EmojiPicker onEmojiClick={handleEmojiClick} open={true} locale="pt" theme={theme.palette.mode} />
                </Box>
            )}
            {whatVariavel ?
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    {whatVariavel.map((variavel, index) => (
                        <MenuItem key={index} onClick={() => handleVariableClick(variavel.nome.replace('{{', '{').replace('[[', '['))}>
                            {variavel.nome}
                        </MenuItem>
                    ))}
                </Menu>
                : null}
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    ), [value, type, prevData, showEmojiPicker, anchorEl, MInfo, cursorPosition, variaveis, theme]);
});


export const TopBarTField = ({ value, onChangeText, placeHolder }) => {
    const user = useContext(User); // Use o contexto do usuário para acessar user?.data?.permissoes
    const [open, setOpen] = useState(false)
    const theme = useTheme()
    // Dados de módulos e submódulos do usuário
    const modulos = user?.data?.permissoes || [];

    // Função para transformar o array de módulos e submódulos em um array plano
    function flattenInside(modulos) {
        const flattenedModules = [];
        modulos.forEach(modulo => {
            if (modulo.inside && Array.isArray(modulo.inside)) {
                modulo.inside.forEach(submodulo => {
                    flattenedModules.push(submodulo);
                });
            } else {
                flattenedModules.push(modulo);
            }
        });
        return flattenedModules;
    }
    function handleOnClose() {
        setOpen(false)
        onChangeText('')
    }

    // Função para filtrar módulos e submódulos com base no valor de pesquisa
    const filteredModules = flattenInside(modulos);
    useEffect(() => {
        if (value.length > 0) {
            setOpen(true)
        }
        else {
            setOpen(false)
        }
    }, [value])
    return (
        <Autocomplete
            options={filteredModules}
            getOptionLabel={(modulo) => modulo.moduloNome}
            noOptionsText={'Nada encontrado'}
            renderOption={(modulo) => {
                let getModulo = filteredModules.filter((e) => e.moduloNome === modulo.key)
                getModulo = getModulo[0]
                if (getModulo?.moduloTipo) {
                    let mainModulo = modulos.filter((e) => e.moduloID === getModulo.moduloTipo)
                    return (
                        <Box sx={{ pl: 2, pr: 1 }} key={getModulo.moduloNome}>
                            <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                                {mainModulo[0].moduloNome}
                            </Typography>
                            <Collapse in={true}>
                                <ListItemButton
                                    component={Link} // Se houver inside, não será um link direto
                                    to={getModulo.moduloEndp} // Se tiver inside, não define o link
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        {getIcon(getModulo.moduloIcon)}
                                        <Typography>{getModulo.moduloNome}</Typography>
                                    </Box>
                                </ListItemButton>
                            </Collapse>
                        </Box>
                    )
                }
                return (
                    <ListItemButton
                        component={Link}
                        to={getModulo.moduloEndp}
                        sx={{ background: 'red' }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            {getIcon(getModulo.moduloIcon)}
                            <Typography>{getModulo.moduloNome}</Typography>
                        </Box>
                    </ListItemButton>
                )
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    value={value}
                    placeholder={placeHolder ? placeHolder : ''}
                    onChange={(event) => {
                        if (onChangeText) {
                            onChangeText(event.target.value);
                        }
                    }}
                    sx={{
                        borderRadius: 1,
                        border: 1,
                        background: theme.palette.background.default,
                        borderColor: theme.palette.divider,
                        "& fieldset": { border: 'none' },
                        '& .MuiOutlinedInput-root': {
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderWidth: '0.01rem',
                                borderColor: 'transparent'
                            }
                        },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '0.01rem',
                            borderColor: 'transparent'
                        },
                    }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <>
                                <SearchIcon style={{ marginRight: '8px', color: '#6B7280' }} />
                                {params.InputProps.startAdornment}
                            </>
                        ),
                        sx: { fontSize: 15, height: 40, textDecoration: 'none', color: theme.palette.text.primary }
                    }}
                />
            )}
            sx={{ width: 600 }}
            onClose={handleOnClose}
            open={open}
            slotProps={{
                paper: {
                    sx: {
                        background: theme.palette.background.default,
                    }
                }
            }}
        />
    );
};