import ReplayIcon from '@mui/icons-material/Replay';
import LabelIcon from '@mui/icons-material/Label';
import { CircularProgress } from '@mui/material';
import HubIcon from '@mui/icons-material/Hub';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import ExtensionIcon from '@mui/icons-material/Extension';
import GamesIcon from '@mui/icons-material/Games';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import LinkIcon from '@mui/icons-material/Link';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import LogoDevIcon from '@mui/icons-material/LogoDev';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CallIcon from '@mui/icons-material/Call';
import DiningIcon from '@mui/icons-material/Dining';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DiscountIcon from '@mui/icons-material/Discount';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import ForumIcon from '@mui/icons-material/Forum';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import RadioIcon from '@mui/icons-material/Radio';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import VerifiedIcon from '@mui/icons-material/Verified';
import ReportIcon from '@mui/icons-material/Report';
import SignalWifiStatusbar4BarIcon from '@mui/icons-material/SignalWifiStatusbar4Bar';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LoginIcon from '@mui/icons-material/Login';
import FaceIcon from '@mui/icons-material/Face';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ContactsIcon from '@mui/icons-material/Contacts';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import MenuIcon from '@mui/icons-material/Menu';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WcIcon from '@mui/icons-material/Wc';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import HomeIcon from '@mui/icons-material/Home';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import SyncIcon from '@mui/icons-material/Sync';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SearchIcon from '@mui/icons-material/Search';
import TelegramIcon from '@mui/icons-material/Telegram';
import SettingsIcon from '@mui/icons-material/Settings';
import CheckIcon from '@mui/icons-material/Check';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EmailIcon from '@mui/icons-material/Email';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import StarIcon from '@mui/icons-material/Star';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import TimerIcon from '@mui/icons-material/Timer';
import ChatIcon from '@mui/icons-material/Chat';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

export const getIcon = (value, color, fontSize, all) => {
    const iconStyle = {
        color: color,
        fontSize: fontSize,
    }
    const icons = {
        '1': <HubIcon sx={iconStyle} />,
        '2': <ArrowRightIcon sx={iconStyle} />,
        '3': <ElectricBoltIcon sx={iconStyle} />,
        '4': <ExtensionIcon sx={iconStyle} />,
        '5': <GamesIcon sx={iconStyle} />,
        '6': <HeadphonesIcon sx={iconStyle} />,
        '7': <InsertChartIcon sx={iconStyle} />,
        '8': <LinkIcon sx={iconStyle} />,
        '9': <LocalGroceryStoreIcon sx={iconStyle} />,
        '10': <LogoDevIcon sx={iconStyle} />,
        '11': <ManageAccountsIcon sx={iconStyle} />,
        '12': <CallIcon sx={iconStyle} />,
        '13': <DiningIcon sx={iconStyle} />,
        '14': <DirectionsCarIcon sx={iconStyle} />,
        '15': <DiscountIcon sx={iconStyle} />,
        '16': <FitnessCenterIcon sx={iconStyle} />,
        '17': <ForumIcon sx={iconStyle} />,
        '18': <FreeBreakfastIcon sx={iconStyle} />,
        '19': <NotificationsIcon sx={iconStyle} />,
        '20': <PhonelinkIcon sx={iconStyle} />,
        '21': <RadioIcon sx={iconStyle} />,
        '22': <RocketLaunchIcon sx={iconStyle} />,
        '23': <VerifiedIcon sx={iconStyle} />,
        '24': <ReportIcon sx={iconStyle} />,
        '25': <CircularProgress sx={iconStyle} />,
        '26': <SignalWifiStatusbar4BarIcon sx={iconStyle} />,
        '27': <EditIcon sx={iconStyle} />,
        '28': <DeleteIcon sx={iconStyle} />,
        '29': <LoginIcon sx={iconStyle} />,
        '30': <FaceIcon sx={iconStyle} />,
        '31': <ViewModuleIcon sx={iconStyle} />,
        '32': <GroupIcon sx={iconStyle} />,
        '33': <PersonIcon sx={iconStyle} />,
        '34': <PhotoCameraIcon sx={iconStyle} />,
        '35': <ContactsIcon sx={iconStyle} />,
        '36': <KeyboardArrowDownIcon sx={iconStyle} />,
        '37': <KeyboardArrowUpIcon sx={iconStyle} />,
        '38': <PhoneIphoneIcon sx={iconStyle} />,
        '39': <ContactEmergencyIcon sx={iconStyle} />,
        '40': <MenuIcon sx={iconStyle} />,
        '41': <LocalActivityIcon sx={iconStyle} />,
        '42': <PeopleAltIcon sx={iconStyle} />,
        '43': <LogoutIcon sx={iconStyle} />,
        '44': <WcIcon sx={iconStyle} />,
        '45': <ShoppingBasketIcon sx={iconStyle} />,
        '46': <HomeIcon sx={iconStyle} />,
        '47': <ViewInArIcon sx={iconStyle} />,
        '48': <InboxIcon sx={iconStyle} />,
        '49': <MailIcon sx={iconStyle} />,
        '50': <LocalShippingIcon sx={iconStyle} />,
        '51': <SyncIcon sx={iconStyle} />,
        '52': <LocalOfferIcon sx={iconStyle} />,
        '53': <LabelIcon sx={iconStyle} />,
        '54': <SearchIcon sx={iconStyle} />,
        '55': <ContactPhoneIcon sx={iconStyle} />,
        '56': <ReplayIcon sx={iconStyle} />,
        '57': <TelegramIcon sx={iconStyle} />,
        '58': <SettingsIcon sx={iconStyle} />,
        '59': <CheckIcon sx={iconStyle} />,
        '60': <FilePresentIcon sx={iconStyle} />,
        '61': <TextFieldsIcon sx={iconStyle} />,
        '62': <DescriptionIcon sx={iconStyle} />,
        '63': <InsertDriveFileIcon sx={iconStyle} />,
        '64': <SubtitlesIcon sx={iconStyle} />,
        '65': <DynamicFormIcon sx={iconStyle} />,
        '66': <NotificationsActiveIcon sx={iconStyle} />,
        '67': <FullscreenIcon sx={iconStyle} />,
        '68': <NoteAddIcon sx={iconStyle} />,
        '69': <KeyboardArrowLeftIcon sx={iconStyle} />,
        '70': <KeyboardArrowRightIcon sx={iconStyle} />,
        '71': <EmailIcon sx={iconStyle} />,
        '72': <MarkEmailReadIcon sx={iconStyle} />,
        '73': <AccountTreeIcon sx={iconStyle} />,
        '74': <NoteAddOutlinedIcon sx={iconStyle} />,
        '75': <AltRouteIcon sx={iconStyle} />,
        '76': <StarIcon sx={iconStyle} />,
        '77': <SupportAgentIcon sx={iconStyle} />,
        '78': <TimerIcon sx={iconStyle} />,
        '79': <ChatIcon sx={iconStyle} />,
        '80': <SupervisedUserCircleIcon sx={iconStyle} />,
    }
    if (all) return icons
    if (!icons.hasOwnProperty(value)) return <NotInterestedIcon sx={iconStyle} />
    return icons[value]
}