import { Box, useTheme} from '@mui/material'
import { ModuloTitle } from '../../../components/moduloTitle'


export function Conversas({ modulo }) {
    document.title = `Conversas - It's Chat`
    const theme = useTheme()
    return (
        <Box sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: '100%'
        }}>
            <ModuloTitle icon={modulo.moduloIcon} title={modulo.moduloNome} desc={modulo.moduloDesc} />
            <Box sx={{ display: 'flex', flexGrow: 1, background: theme.palette.background.default, width: '100%', height: '100%' }}>

            </Box>
        </Box>
    )
}