import { useContext, useState } from 'react'
import { Box, Button, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { getIcon } from '../../../components/icons'
import { BoxHover } from '../../../components/boxHover'
import { ModalAuth } from '../../../components/modalAuth'
import { ModalInfo } from '../../../components/modalInfo'
import { validaEvento } from '../../../Mainfunctions'
import MAPI from '../../../api/mainApi'
import { User } from '../../../contexts/user'
import { UpComp } from '../../../components/upComp'


export function UnitConex({ conexao, permissoes, deleteData, updateEditCon, verifyStatus }) {
    const theme = useTheme()
    const user = useContext(User)
    const textStyle = {
        color: theme.palette.text.primary,
        textAlign: 'center'
    }
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    })
    const [modalAuth, setModalAuth] = useState(false)

    async function handleButton(type) {
        if (type === 'Excluir') {
            if (validaEvento(permissoes, 'excluir') === false) {
                updateModalInfo(true, true, 'Atenção', 'Você não tem permissão para excluir uma conexão', 'exclamation')
                return
            }
            updateModalInfo(true, true, 'Atenção', 'Você tem certeza que deseja excluir essa conexão e todos os dados relacionados à ela?', 'question', handleExcluir)
            return
        }
        if (type === 'Editar') {
            if (validaEvento(permissoes, 'editar') === false) {
                updateModalInfo(true, true, 'Atenção', 'Você não tem permissão para editar uma conexão', 'exclamation')
                return
            }
            updateEditCon(true, conexao)
            return
        }
        if (type === 'Status') {
            if (validaEvento(permissoes, 'status') === false) {
                updateModalInfo(true, true, 'Atenção', 'Você não tem permissão para verificar o status de uma conexão', 'exclamation')
                return
            }
            verifyStatus(conexao)
        }
    }

    async function handleExcluir() {
        if (modalAuth === false) {
            setModalAuth(true)
            return
        }
        const TRYDelete = await MAPI('conexoes/excluir/' + conexao.id, 'DELETE', null, user?.data?.token)
        if (TRYDelete?.status !== 200) {
            const message = TRYDelete?.apiReturn?.message ? TRYDelete?.apiReturn?.message : 'Ocorreu um erro ao excluir a conexão.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
        }
        deleteData(conexao)
        setModalAuth(false)
        updateModalInfo(false, true, 'Atenção', 'Conexão excluída com sucesso.', 'sucess')
    }

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo }
        newMInfo.visible = visible
        newMInfo.canDesactive = canDesactive
        newMInfo.title = title
        newMInfo.subtitle = subtitle
        newMInfo.icon = icon
        newMInfo.funcS = func
        setMInfo(newMInfo)
    }

    return (
        <BoxHover>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: 200, width: '70%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 60, width: 60, borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                    {
                        getIcon(conexao.icon, theme.palette.text.primary, 40)
                    }
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Typography sx={[textStyle, { fontWeight: 'bold', fontSize: 22 }]}>{conexao?.nome}</Typography>
                    <Typography sx={textStyle}>{conexao?.descricao}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    {['Status', 'Editar', 'Excluir'].map((e, idx) => {
                        const icon = {
                            Status: 26,
                            Editar: 27,
                            Excluir: 28
                        }
                        const color = idx % 2 ? theme.palette.secondary.main : theme.palette.tertiary.main
                        return (
                            <UpComp tamanho={30} key={e}>
                                <Tooltip key={e} title={e}>
                                    <IconButton
                                        onClick={() => handleButton(e)}
                                    >
                                        {getIcon(icon[e], color)}
                                    </IconButton>
                                </Tooltip>
                            </UpComp>
                        )
                    })}
                </Box>
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            <ModalAuth visible={modalAuth} funcReturn={handleExcluir} hideModal={() => setModalAuth(false)} />
        </BoxHover>
    )
}

/* {
    "id": 1,
        "user": 1,
            "nome": "teste",
                "descricao": "teste",
                    "icon": 1,
                        "status": 1
} */